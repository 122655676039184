$(function(){
    ///////////////////////////
    // add custom scripts below
    //////////////////////////

    //testing data autopopulate
    $('.testing-data span, .form-testing-data span').click(function(){
        var fnameField = $('#inf_field_FirstName');
        var lnameField = $('#inf_field_LastName');
        var phoneField = $('#inf_field_Phone1');
        var emailField = $('#inf_field_Email');
        var addy1Field = $('#inf_field_Address2Street1');
        var addy2Field = $('#inf_field_Address2Street2');
        var cityField = $('#inf_field_City2');
        var stateField = $('#inf_field_State2');
        var zipField = $('#inf_field_PostalCode2');

        var guestFnameField = $('.inf_custom_GuestFirstName');
        var guestLnameField = $('.inf_custom_GuestLastName');
        var guestEmailField = $('.inf_custom_Guest1Email');

        var fname = 'Jane';
        var lname = 'Doe';
        var phone = '555-555-5555';
        var email = 'janedoe@email.com';
        var addy1 = '1244 main ave';
        var addy2 = 'apt 3443';
        var city = 'denver';
        var state = 'colorado';
        var zip = '71102';
        var guestFname = 'Friend';
        var guestLname = 'Doe';
        var guestEmail = 'friendsemail@email.com';

        fnameField.val(fname);
        lnameField.val(lname);
        phoneField.val(phone);
        emailField.val(email);
        addy1Field.val(addy1);
        addy2Field.val(addy2);
        cityField.val(city);
        stateField.val(state);
        zipField.val(zip);

        guestFnameField.val(guestFname);
        guestLnameField.val(guestLname);
        guestEmailField.val(guestEmail);
        

    });


})